import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import React, { FC, useEffect, useRef } from 'react';
import BannerIcon from '@/assets/img/banner_icon.png';
import Arrow from '@/assets/img/icons/icon_moreview_link@2x.png';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'dayjs/locale/ko';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

SwiperCore.use([Navigation, A11y, Autoplay]);

const SingleHistory = styled.div`
  .single_history {
    margin-top: 50px;
  }
  ${breakpoint(`tablet`)} {
    .single_history {
      margin-top: 30.5px;
    }
  }
`;

const SwiperCustom = styled(Swiper)`
  margin: 50px auto;
  padding: 0;
  .swiper-slide {
    overflow: hidden;
    &:last-child {
      margin-right: 0;
    }
  }
  .swiper-scrollbar {
    display: none;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 56px;
    height: 56px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    background: #fff;
    position: absolute;
    top: 50%;
    bottom: 10px;
    cursor: pointer;
    transform: translate(-0%, -50%);
    margin-top: 0;
    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url(${Arrow}) center no-repeat;
      background-size: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .swiper-button-prev {
    left: 38px;
    top: 50%;
    &::after {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    ${breakpoint(1160)} {
      & {
        margin: 0;
        right: 100px;
      }
    }
  }
  .swiper-button-next {
    right: 38px;
    top: 50%;
    ${breakpoint(1160)} {
      & {
        margin: 0;
        right: 20px;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    margin: 30.5px auto;
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    .swiper-scrollbar {
      display: block;
      bottom: 23.5px;
      width: calc(100% - 48px);
      transform: translate(-50%, -50%);
      left: 50%;
      .swiper-scrollbar-drag {
        /* width: 55px !important; */
        height: 4px;
        background: rgb(28, 171, 226);
      }
    }
  }
`;

const ReceiptBannerWrap = styled.div`
  padding: 27px 124px;
  background-color: #fff;
  border: 2px solid #1cabe2;
  border-radius: 4px;
  height: 169px;
  display: flex;
  justify-content: space-between;

  ${breakpoint('tablet')} {
    height: auto;
    padding: 24px 25px 62.5px;
  }
`;

const ReceiptBannerImg = styled.img`
  width: 109px;
  height: 107px;

  ${breakpoint('tablet')} {
    display: none;
  }
`;

const ReceiptBanneContents = styled.div`
  flex-grow: 1;
  margin-left: 39px;

  & > strong {
    margin-bottom: 7px;
    margin-top: -3px;
  }
  ${breakpoint(`tablet`)} {
    margin: 0 auto;
    width: auto;
    & > strong {
      width: 300px;
      margin: 0 auto 9px auto;
      font: 14px;
      display: block;
    }
  }
`;

const ReceiptBanneText = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #2d2926 !important;
  }
  & > li {
    width: 25%;
    line-height: 28px;
    & > p {
      color: #1cabe2;
      font-weight: bold;
      font-size: 16px;
    }
  }
  ${breakpoint(`tablet`)} {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    .ellipsis {
      -webkit-line-clamp: 1;
    }
    & > li {
      width: 100%;
      display: flex;
      line-height: 24px;
      justify-content: space-between;
      & > p {
        margin-right: 32px;
        width: 100%;
        max-width: 72px;
        font-size: 14px;
      }
      & > span {
        display: block;
        width: 142px;
      }
    }
  }
`;

// 날짜와 시간을 원하는 포맷으로 변환하는 함수
const formatDate = (date: string, startTime: string, endTime: string) => {
  dayjs.locale('ko'); // 한국어 설정
  const formattedDate = dayjs(date).format('YYYY년 M월 D일 (ddd)');
  const formattedStartTime = `${parseInt(
    startTime.slice(0, 2),
    10,
  )}:${startTime.slice(2)}`;

  // const formattedEndTime = `${parseInt(endTime.slice(0, 2), 10)}시 ${parseInt(
  //   endTime.slice(2),
  //   10,
  // )}분`;

  return (
    <>
      {formattedDate}
      <br />
      {formattedStartTime} ~ {/* {formattedEndTime} */}
    </>
  );
};

const RenderContent: FC<{ entry: any }> = ({ entry }) => {
  const dateAndTime = formatDate(
    entry.selectDate,
    entry.startTime,
    // entry.endTime,
  );

  return (
    <ReceiptBannerWrap>
      <ReceiptBannerImg src={BannerIcon} />
      <ReceiptBanneContents>
        <Tit size="s7">유니세프 어린이체험관 신청내역</Tit>
        <ReceiptBanneText>
          <li>
            <p>체험일시</p>
            <span>{dateAndTime}</span>
          </li>
          <li>
            <p>기관명</p>
            <span>{entry.agency}</span>
          </li>
          <li>
            <p>인원 / 구분</p>
            <span>
              {entry.organType} {entry.personnelCount}명
              {['초등', '중등', '고등'].includes(entry.organType) &&
              entry.organGrade ? (
                <>({entry.organGrade}학년)</>
              ) : null}
              <br />
              성인 {entry.accompanyCnt}
              {
                !isNaN(Number(entry.accompanyCnt))
                  ? '명'
                  : '' /* 숫자일 경우만 단위 추가 */
              }
            </span>
          </li>
          <li>
            <p>요청사항</p>
            <span className="ellipsis">{entry.contents}</span>
          </li>
        </ReceiptBanneText>
      </ReceiptBanneContents>
    </ReceiptBannerWrap>
  );
};

const ChildHistory: FC<{ data: any[] }> = ({ data }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.update();
    }
  }, [data]);

  if (!data || data.length === 0) {
    return false;
  }

  return data.length > 1 ? (
    <SwiperCustom
      ref={swiperRef}
      slidesPerView={1}
      navigation
      scrollbar={{ draggable: true }}
      autoplay={{ delay: 5000 }}
      spaceBetween={20}
    >
      {data.map((entry, index) => (
        <SwiperSlide key={index}>
          <RenderContent entry={entry} />
        </SwiperSlide>
      ))}
    </SwiperCustom>
  ) : (
    <div className="single_history" style={{ marginTop: '50px' }}>
      <RenderContent entry={data[0]} />
    </div>
  );
};

export default ChildHistory;
